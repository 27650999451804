export default class BrockmanGallery {
  constructor(args) {
    // Args
    this.gallery = args.target;
    this.minSwipe = args.minSwipe || 100;

    // Event handlers
    this.keydownHandler = null;
    this.swipeXOrigin = null;

    // Counters
    this.currentCount = 0;
    this.maxCount = 0;

    // Elements
    this.caption = null;
    this.current = null;
    this.image = null;
    this.thumbnailsWrapper = null;

    this.figures = [];
    this.thumbnails = [];

    this.ad = null;
    this.adId = args.adId || 'AE_MPU_MOB';
    this.adsSite = args.adsSite || null;
    this.adCount = 0;
  }

  run() {
    if (!this.gallery) return;
    this.figures = this.getFigures();
    this.maxCount = this.figures.length;
    this.setGalleryNumber();
    this.buildInitial();
    this.activate();
  }

  buildInitial() {
    this.image = this.gallery.querySelector('.image');
    this.inlineCaption = this.gallery.querySelector('.inline-gallery-caption');
    this.fullscreenCaption = this.gallery.querySelector('.fullscreen_info .caption');
    this.fullscreenCaptionText = this.gallery.querySelector('.fullscreen_info .caption .text');
    this.fullscreenAttribution = this.gallery.querySelector('.fullscreen_info .attribution');
    this.fullscreenAttributionText = this.gallery.querySelector('.fullscreen_info .attribution .text');
    this.current = this.gallery.querySelector('.current');
    this.thumbnailsWrapper = this.gallery.querySelector('.thumbnails');
    this.loading = this.gallery.querySelector('.loading.spinner.large');

    // Ads
    this.adContainer = this.gallery.querySelector('.advert_container');

    const imageWrapper = this.gallery.querySelector('.image_wrapper');
    const next = this.gallery.querySelector('.next');
    const previous = this.gallery.querySelector('.previous');
    const close = this.gallery.querySelector('.close');
    this.toggle = this.gallery.querySelector('.toggle');

    // Add thumbnails
    this.thumbnails = this.gallery.querySelectorAll('.thumbnail');
    this.thumbnails.forEach((element, index) => {
      element.querySelector('img').setAttribute('index', index);
      element.addEventListener('click', this.click.bind(this));
    });

    // Set up counter
    this.counter = this.gallery.querySelector('.counter');
    this.counter.querySelector('.max').innerHTML = this.maxCount;

    // ...pagination
    previous.addEventListener('click', this.previous.bind(this));
    next.addEventListener('click', this.next.bind(this));
    this.toggle.addEventListener('click', this.toggleMobileFullscreenCaption.bind(this));
    close.addEventListener('click', this.toggleFullscreen.bind(this));
    imageWrapper.addEventListener('touchstart', this.swipeStart.bind(this));
    imageWrapper.addEventListener('touchend', this.swipeEnd.bind(this));
    imageWrapper.addEventListener('click', this.toggleFullscreen.bind(this));

    // ...image loaded
    this.image.addEventListener('load', this.imageLoaded.bind(this));

    // Keyboard keys
    this.keydownHandler = this.keydown.bind(this);

    this.gallery.dataset.init = true;
  }

  getFigures() {
    const figures = this.gallery.querySelectorAll('.thumbnail');
    return [].slice.call(figures);
  }

  // Scroll thumbnails container so that active thumbnail is on the left
  scrollThumbnails() {
    const scrollLeft = this.thumbnails[this.currentCount].offsetLeft - this.thumbnailsWrapper.offsetLeft;
    this.thumbnailsWrapper.scrollLeft = scrollLeft;
  }

  // Event handlers...
  // Called when a thumbnail is clicked
  click(e) {
    this.clear(e);
    let index = '-1';
    if (e.target.tagName === 'BUTTON') {
      index = e.target.firstElementChild.attributes.index.value;
    } else {
      index = e.target.attributes.index.value;
    }
    if (index) {
      this.currentCount = parseInt(index, 10);
    }
    e.target.blur();
    this.activate();
    this.onChange();
  }

  activate() {
    this.imageLoading();
    this.updateImage();
    this.updateThumbnails();
    this.updateCounter();
    this.updateInfo();
  }

  updateImage() {
    // Find image in source markup
    const image = this.figures[this.currentCount];
    const imgElement = image.querySelector('img');
    let url = '';
    let alt = '';
    if (this.gallery.classList.contains('fullscreen')) url = image.dataset.href;
    else url = image.dataset.inlineImageHref;
    if (imgElement && imgElement.alt) alt = imgElement.alt;
    this.startLoad(url, alt);
  }

  updateCounter() {
    this.current.innerText = this.currentCount + 1;
  }

  updateInfo() {
    const { caption } = this.thumbnails[this.currentCount].dataset;
    const { attribution } = this.thumbnails[this.currentCount].dataset;
    if (caption === undefined) {
      this.inlineCaption.innerHTML = '';
      this.fullscreenCaption.style.display = 'none';
      this.fullscreenCaptionText.innerHTML = '';
    } else {
      this.inlineCaption.innerHTML = caption;
      this.fullscreenCaption.style.display = 'block';
      this.fullscreenCaptionText.innerHTML = caption;
    }
    if (attribution === undefined) {
      this.fullscreenAttribution.style.display = 'none';
      this.fullscreenAttributionText.innerHTML = '';
    } else {
      this.fullscreenAttribution.style.display = 'block';
      this.fullscreenAttributionText.innerHTML = attribution;
    }
  }

  updateThumbnails() {
    this.thumbnails.forEach((element) => {
      // First deactivate all thumbnails
      element.classList.remove('active');
    });

    // Set thumbnail to active
    this.thumbnails[this.currentCount].classList.add('active');
  }

  // Start loading a new image
  startLoad(url, alt) {
    this.image.src = '';
    this.image.src = url;
    this.image.alt = alt;
  }

  // Called before the gallery image is loaded
  imageLoading() {
    this.image.classList.add('image--loading');
    this.loading.style.display = 'block';
  }

  // Called when the gallery image finshes loading
  imageLoaded() {
    this.image.classList.remove('image--loading');
    this.loading.style.display = 'none';
  }

  toggleFullscreen() {
    this.gallery.classList.toggle('fullscreen');
    this.activate();
    if (this.gallery.classList.contains('fullscreen')) {
      this.buildAd();
      document.body.classList.add('overflow-hidden');
      document.addEventListener('keydown', this.keydownHandler);
      this.scrollThumbnails();
      window.sendZDAnalyticsEvent('slideshow_open', 'Content', 'Slideshow', 'Open');
    } else {
      this.destroyAd();
      document.body.classList.remove('overflow-hidden');
      document.removeEventListener('keydown', this.keydownHandler);
    }
  }

  // Called when the toggle button is clicked
  toggleMobileFullscreenCaption(e) {
    this.clear(e);
    this.toggle.classList.toggle('active');
    this.gallery.dataset.details = this.gallery.dataset.details !== 'true';
  }

  // Called when the next pagination button is clicked
  next(e) {
    this.clear(e);
    this.currentCount += 1;
    if (this.currentCount >= this.maxCount) {
      this.currentCount = 0;
    }
    this.activate();
    this.scrollThumbnails();
    this.onChange();
  }

  // Called when the previous pagination button is clicked
  previous(e) {
    this.clear(e);
    this.currentCount -= 1;
    if (this.currentCount < 0) {
      this.currentCount = this.maxCount - 1;
    }
    this.activate();
    this.scrollThumbnails();
    this.onChange();
  }

  onChange() {
    window.sendZDAnalyticsEvent('slideshow_changeslide', 'Content', 'Slideshow', 'Change Slide');
  }

  // Called when the close button is clicked
  close(e) {
    this.clear(e);
    this.destroy();
  }

  // Called by other click events
  clear(e) {
    if (!e) return;
    e.preventDefault(); // Don't call both touch and click events
    e.stopPropagation();
    e.target.blur(); // Remove focus after button is clicked
  }

  // Called when a key is pressed
  keydown(e) {
    switch (e.keyCode) {
      case 27: // escape
        e.preventDefault();
        if (this.gallery.classList.contains('fullscreen')) {
          this.toggleFullscreen();
        }
        break;
      case 37: // left arrow
        e.preventDefault();
        this.previous();
        break;
      case 39: // right arrow
        e.preventDefault();
        this.next();
        break;
      default:
        break;
    }
  }

  // When a swipe ends, if the distance from the start is > minSwipe then go to
  // previous or next image depending on swipe direction
  swipeStart(e) {
    this.swipeXOrigin = e.changedTouches[0].clientX;
  }

  swipeEnd(e) {
    const touch = e.changedTouches[0];
    if (this.swipeXOrigin && touch) {
      const dx = touch.clientX - this.swipeXOrigin;
      const s = Math.sign(dx);
      const a = Math.abs(dx);
      if (a > this.minSwipe) {
        if (s > 0) this.previous();
        else this.next();
      } else if (this.gallery.classList.contains('fullscreen')) {
        // On a touch device clicking on the image shouldn't close the gallery
        this.clear(e);
        this.next();
      }
    }
    this.swipeXOrigin = null;
  }

  setGalleryNumber() {
    // Give galleries unique IDs for advert containers
    const galleries = document.querySelectorAll('div.gallery');
    galleries.forEach((element, index) => {
      if (element === this.gallery) {
        this.galleryNumber = index;
      }
    });
  }

  buildAd() {
    this.adContainer.innerHTML = this.getAdMarkup();
    this.ad = this.gallery.querySelector('.autoad');
    setTimeout(this.requestAd.bind(this));
  }

  destroyAd() {
    this.adContainer.innerHTML = '';
  }

  getAdMarkup() {
    if (!this.adsSite) return '';
    this.adCount += 1;
    return `
        <div
          class="autoad"
          data-dfp-id="${this.adId}"
          data-dfp-collapse="false"
          data-dfp-refresh="60"
          data-dfp-targeting="lazyload=true,position=gallery,site=${this.adsSite}"
          id="autoad_gallery_gallery-${this.galleryNumber}_${this.adCount}"
        ></div>
    `;
  }

  requestAd() {
    if (!this.adsSite) return;
    // Set ad sizes requested depending on available space
    const container = this.ad.closest('.advert_container');
    const pos = container.getBoundingClientRect();
    if (pos.width === 300) {
      this.ad.dataset.dfpSizes = '300x250';
    } else {
      this.ad.dataset.dfpSizes = '320x50';
    }

    // Send the ad request
    if (window.brockmanAds) {
      window.brockmanAds.requestAd(this.ad);
    }
  }
}
