export default class BrockmanLightbox {
  constructor() {
    this.loc = {
      next: 'Next',
      previous: 'Previous',
    };

    this.selector = 'a[data-lightbox="true"]';
    this.doneSelector = 'a[data-lightbox="done"]';
    this.allowedImageFormats = [
      'bmp',
      'jpeg',
      'jpg',
      'jfif',
      'gif',
      'png',
      'webp',
    ];

    this.isOpen = false;
    this.lightbox = null;
    this.image = null;
    this.keydownHandler = null;
    this.closeCallback = null;
    this.lightboxedElements = [];
    this.currentIndex = -1;
  }

  run(container) {
    const targets = (container || document).querySelectorAll(this.selector);
    targets.forEach((target) => {
      const a = target.closest('a');

      if (!a) {
        this.invalid(target);
        return;
      }

      const href = a.getAttribute('href');
      if (!this.isImage(href)) {
        this.invalid(target);
        return;
      }

      a.addEventListener('click', (e) => this.click(e));
      a.dataset.lightbox = 'done';
    });

    this.lightboxedElements = [...document.querySelectorAll(this.doneSelector)];
  }

  invalid(target) {
    target.dataset.lightbox = 'invalid';
  }

  click(e) {
    e.preventDefault();
    e.stopPropagation();
    const target = e.currentTarget.closest(this.doneSelector);
    this.currentIndex = this.lightboxedElements.indexOf(target);
    this.renderImage();
  }

  open(closeCallback = null) {
    if (this.isOpen) return;

    this.renderLightbox();

    this.keydownHandler = this.keydown.bind(this);
    document.addEventListener('keydown', this.keydownHandler);
    this.isOpen = true;

    this.image = document.createElement('img');
    this.image.setAttribute('alt', '');
    this.image.addEventListener('load', () => this.stopLoading());
    this.lightbox.prepend(this.image);

    this.closeCallback = closeCallback;
  }

  close() {
    if (!this.isOpen) return;

    this.lightbox.remove();
    document.removeEventListener('keydown', this.keydownHandler);

    this.isOpen = false;
    this.image = null;
    this.currentIndex = -1;

    if (this.closeCallback) this.closeCallback();
    this.closeCallback = null;
  }

  renderImage() {
    if (
      this.currentIndex === -1 ||
      this.currentIndex >= this.lightboxedElements.length
    )
      return;

    const target = this.lightboxedElements[this.currentIndex];
    const href = target.getAttribute('href');

    this.open();
    this.startLoading();

    this.image.src = '';
    this.image.src = href;
    this.image.setAttribute('alt', this.getAltText(target));
  }

  getAltText(target) {
    const { alt } = target.dataset;
    if (alt) return alt;
    const img = target.querySelector('img');
    if (img) return img.getAttribute('alt') || '';
    return '';
  }

  stopLoading() {
    const spinner = this.lightbox.querySelector('.spinner');
    if (spinner) spinner.remove();
  }

  startLoading() {
    if (this.lightbox.querySelector('.spinner')) return;
    const spinner = document.createElement('span');
    spinner.classList.add('spinner', 'large');
    this.lightbox.append(spinner);
  }

  renderLightbox() {
    this.lightbox = document.createElement('div');
    this.lightbox.classList.add('lightbox_fullscreen');
    this.lightbox.addEventListener('click', () => this.close());
    this.renderPagination();
    document.body.appendChild(this.lightbox);
  }

  renderPagination() {
    if (this.lightboxedElements.length <= 1) return;

    this.lightbox.innerHTML = `
      <nav class="pagination">
        <button class="previous button" aria-label="${this.loc.previous}"></button>
        <button class="next button" aria-label="${this.loc.next}"></button>
      </nav>
    `;

    this.lightbox
      .querySelector('.previous')
      .addEventListener('click', (e) => this.previous(e));

    this.lightbox
      .querySelector('.next')
      .addEventListener('click', (e) => this.next(e));
  }

  previous(e) {
    this.paginate(e, -1);
  }

  next(e) {
    this.paginate(e, 1);
  }

  paginate(e, offset) {
    e.preventDefault();
    e.stopPropagation();
    this.currentIndex = this.getElementIndex(offset);
    this.renderImage();
  }

  getElementIndex(offset) {
    const count = this.lightboxedElements.length;
    let index = this.currentIndex;
    if (index === -1) return null;
    index += offset;
    if (index < 0) return count + index;
    if (index >= count) return index - count;
    return index;
  }

  keydown(e) {
    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        this.close();
        break;

      case 'ArrowLeft':
        this.previous(e);
        break;

      case 'ArrowRight':
        this.next(e);
        break;

      default:
        break;
    }
  }

  isImage(uri) {
    const formats = this.allowedImageFormats.join('|');
    const re = new RegExp(`\\.(${formats})\\/?($|\\?)`, 'i');
    return uri.match(re) !== null;
  }
}
